@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.moments-layout {
  min-height: 100vh;
  .scroll-box {
    height: calc(100vh - 96px);
    .scroller();
  }
}
.moments-list {
  box-shadow: 0px 1px 0px 0px rgba(38, 38, 38, 0.2);
  padding: 16px 0;
}
.moments-text {
  color: #262626;
  font-size: 12px;
  .moments-img {
    width: 84px;
    height: 84px;
    display: inline-block;
  }
  .moments-img1 {
    max-width: 120px;
    max-height: 240px;
    border-radius: 4px;
  }
  .img-insert {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  .moments-card {
    background: #f5f0f0;
    border-radius: 4px;
    display: flex;
    padding: 8px 16px 8px 8px;
    cursor: pointer;
    .card-img {
      width: 38px;
      height: 38px;
      border-radius: 4px;
      margin-right: 8px;
    }
    .card-text {
      color: #262626;
      height: 40px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
.moments-infos {
  font-size: 12px;
  line-height: 23px;
}
.time {
  color: #bfbfbf;
}
.icons {
  float: right;
  color: #8c8c8c;
}
.moments-likes,
.moments-comments {
  position: relative;
  background: #dddcdc;
  color: #092b00;
  font-size: 12px;
  font-weight: 500;
  line-height: 32px;
  border-radius: 4px;
  margin-top: 14px;
  &::before {
    position: absolute;
    top: -8px;
    left: 8px;
    content: '';
    border-width: 4px 8px;
    border-style: solid;
    border-color: transparent transparent #dddcdc;
  }
}
.comments-block {
  font-size: 12px;
  padding: 4px 8px;
  line-height: 22px;
}
.moments-oper {
  float: right;
  color: #389e0d;
  cursor: pointer;
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;