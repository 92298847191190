@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.chat-item {
  overflow: hidden;
  font-size: 13px;
  padding: 17px 15px;
  cursor: pointer;
  &:hover {
    background: rgba(56, 158, 13, 0.1) !important;
  }
  .chat-item-avatar,
  .chat-item-avatar-placeholder {
    display: block;
    width: 38px;
    height: 38px;
    flex: 0 0 auto;
    border-radius: 4px;
  }
  .chat-item-text-box {
    display: block;
    overflow: hidden;
    position: relative;
    margin-left: 8px;
    width: 100%;
    line-height: 13px;
    .chat-item-name {
      .ellipsis();
      position: absolute;
      top: 0;
      left: 0;
      line-height: 1.2;
      color: #000;
      max-width: 170px;
    }
    .chat-item-time {
      position: absolute;
      top: 1px;
      right: 0;
      color: #bfbfbf;
      font-size: 12px;
    }
    .chat-item-msg {
      .ellipsis();
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 165px;
      font-size: 12px;
      line-height: 1.2;
      color: #8c8c8c;
    }
    .chat-item-prompt {
      z-index: 0;
      position: absolute;
      bottom: 0;
      height: 14px;
      right: 0;
      color: rgba(120, 120, 120, 0.7);
      .bell-prompt {
        z-index: 0;
        position: relative;
        &::after {
          z-index: 99;
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 12px;
          height: 2px;
          background: rgb(120, 120, 120);
          border-radius: 50%;
          transform: rotateZ(45deg);
        }
      }
    }
  }
}
.chatitem-ignore {
  position: relative;
  .chat-item {
    background: #eeeeee;
  }
}

.chat-item-content {
  position: relative;
}

.chatitem-active {
  .chat-item {
    background: rgba(38, 38, 38, 0.15);
  }
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;