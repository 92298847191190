@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.chat-pannel-input {
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  max-height: calc(100% - 5px);
  textarea::-webkit-input-placeholder {
    color: #8c8c8c;
    font-size: 14px;
  }

  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #8c8c8c;
    font-size: 14px;
  }

  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #8c8c8c;
    font-size: 14px;
  }

  textarea:-ms-input-placeholder {
    color: #8c8c8c;
    font-size: 14px;
  }

  textarea::placeholder {
    color: #8c8c8c;
    font-size: 14px;
  }
  .chat-pannel-input__input {
    resize: none;
    border: none;
    outline: none;
    overflow-y: auto;
  }
  // writing-mode: tb-rl;
  // text-align: left;
  ::-webkit-scrollbar {
    background-color: #eeeeee;
    width: 6px;
    background-clip: padding-box;
  }

  /*滚动条两端方向按钮*/
  ::-webkit-scrollbar-button {
    background-color: #eeeeee;
  }

  /*滚动条中间滑动部分*/
  ::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #c3c3c3;
    border-radius: 3px;
  }
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;