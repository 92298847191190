.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}
.scroller {
  overflow: auto;
}
.scroller::-webkit-scrollbar {
  display: block;
  width: 6px;
}
.scroller::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #c3c3c3;
}
.scroller::-webkit-scrollbar-track {
  background: transparent;
}
.ant-modal-content {
  border-radius: 8px;
}
.ant-modal-content .ant-modal-header {
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}
@font-face {
  font-family: 'iconfont';
  /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}
.client-list {
  box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.15);
  max-height: calc(80vh - 70px);
  background: #fff;
}
.client-list .scroll-list {
  max-height: calc(80vh - 70px - 38px);
  overflow: auto;
}
.client-list .scroll-list::-webkit-scrollbar {
  display: block;
  width: 6px;
}
.client-list .scroll-list::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #c3c3c3;
}
.client-list .scroll-list::-webkit-scrollbar-track {
  background: transparent;
}
.client-list .account-search {
  display: block;
  margin: 3px auto;
  width: 240px;
  height: 32px;
}
.client-list .account-search input {
  font-size: 12px;
  background: #f5f5f5;
  color: #000;
  border: none;
  outline: none;
}
.client-list .account-search svg {
  fill: #aaa;
}
.client-list .empty-tip {
  margin-top: 15px;
  text-align: center;
}
