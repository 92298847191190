@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.records-audio {
  cursor: pointer;
  .wrapper,
  .wrapperRight {
    display: flex;
    align-items: center;
    position: relative;
  }
  .wrapperRight {
    transform: rotateY(180deg);
    .duration {
      position: absolute;
      right: 30px;
      transform: rotateY(180deg);
    }
  }

  .audio-icon,
  .audio-icon-active {
    position: absolute;
    top: 50%;
    left: 6px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-right: 3px solid rgba(51, 51, 51, 0.7);
    border-radius: 50%;
    transform: translateY(-50%);
  }
  .audio-icon::before,
  .audio-icon-active::before,
  .audio-icon::after,
  .audio-icon-active::after {
    opacity: 1;
    z-index: -1;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    border: 2.1px solid transparent;
    border-right-color: rgba(51, 51, 51, 0.7);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .audio-icon::after,
  .audio-icon-active::after {
    width: 24px;
    height: 24px;
  }
  .audio-icon-active::after {
    animation: fade 1s infinite;
    opacity: 0;
  }
  .audio-icon-active::before {
    animation: fade1 1s infinite;
    opacity: 0;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade1 {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    31% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  .audio {
    background: #fff;
    height: 34px;
    width: 74px;
    // border: 1px solid #dcdada;
    border-radius: 4px;
    position: relative;
    left: 10px;
    display: inline-block;
  }
  // .audio::before {
  //   content: '';
  //   width: 0;
  //   height: 0;
  //   display: block;
  //   position: absolute;
  //   border: 10px solid #fff;
  //   border-width: 6px 10px;
  //   border-color: transparent #fff transparent transparent;
  //   left: -20px;
  //   top: 8px;
  // }
  .duration {
    position: absolute;
    left: 35px;
    font-size: 12px;
    color: #999;
  }
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;