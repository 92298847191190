@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.chat-pannel {
  height: 200px;
  border-top: 1px solid rgba(38, 38, 38, 0.15);
  .chat-pannel-header {
    padding: 5px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    .ant-upload-list {
      display: none;
    }
    .chat-pannel-header-icon {
      margin-right: 13px;
      font-size: 18px;
    }
    .anticon-file-image {
      font-size: 20px;
      &:hover {
        cursor: pointer;
      }
    }
    .chat-pannel-header-callme {
      align-self: flex-end;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .chat-pannel-content {
    padding-left: 16px;
    height: 100px;
  }
  .chat-pannel-bottom {
    padding: 0 20px 16px;
    text-align: right;
    height: 52px;
    .send-tips {
      padding: 0 16px;
      color: #bfbfbf;
    }
    .send-btn-default {
      color: #54ab29;
      background-color: unset;
      border-color: #54ab29;
      width: 100px;
    }
  }
}
.sym .chat-pannel-header-callme-list {
  overflow-y: auto;
  max-height: 320px;
  min-width: 300px;
}
.scroll-box {
  .scroller();
}
.chat-pannel-header-callme-list-item {
  border: none;
  font-size: 12px;
  line-height: 30px;
  &:hover {
    cursor: pointer;
    transition: all 0.3s;
    background: rgba(56, 158, 13, 0.2);
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
  p {
    margin: 0 5px;
    strong {
      margin-right: 5px;
      color: #389e0d;
    }
  }
}
.send-title {
  text-align: center;
  margin: 0;
}
.no-at-container.im-empty-status {
  margin-top: 0;
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;