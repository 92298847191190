.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}
.scroller {
  overflow: auto;
}
.scroller::-webkit-scrollbar {
  display: block;
  width: 6px;
}
.scroller::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #c3c3c3;
}
.scroller::-webkit-scrollbar-track {
  background: transparent;
}
.ant-modal-content {
  border-radius: 8px;
}
.ant-modal-content .ant-modal-header {
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}
@font-face {
  font-family: 'iconfont';
  /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'), url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}
.link {
  background: #fff;
  width: 260px;
  box-shadow: 0px 0px 2px #ccc;
  margin: 0 10px;
  padding: 10px;
  display: block;
  color: rgba(0, 0, 0, 0.5);
}
.link .title {
  font-size: 14px;
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #000;
}
.link .content {
  display: flex;
  align-items: center;
}
.link .content .img {
  width: 50px;
  height: 50px;
  display: block;
  background: #e2e2e2;
  margin-right: 10px;
}
.link .content .des {
  font-size: 12px;
  height: 50px;
  overflow: hidden;
  flex: 1;
}
