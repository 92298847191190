@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.express-modal {
  .ant-tabs-bottom .ant-tabs-bottom-bar {
    margin-top: 6px !important;
  }
}
.symbol-express {
  width: 48px;
  height: 48px;
  background: url('/20190727-235848.png');
  zoom: 50%;
  display: inline-block;
  margin: 3px 6px;
}
.emoticon-layout {
  min-height: 266px;
  .scroll-box {
    height: calc(266px - 6px);
    margin-left: -4px;
    overflow: auto;
    .scroller();
  }
}
.emoticon-title {
  color: #262626;
  font-size: 16px;
  padding: 8px 0 16px;
  display: flex;
  justify-content: space-between;
  a {
    color: #262626;
  }
}

.eomticon-img-container {
  position: relative;
  padding: 4px;
  display: inline-block;
  border-radius: 4px;
  margin: 4px;
  .emoticon-checkbox {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.emoticon-img {
  width: 64px;
  height: 64px;
  background: #fff;
}
.express-modal .ant-tabs-tab {
  padding: 16px 0 0 9px !important;
  margin: 0 !important;
}

.ant-upload-text {
  position: absolute;
  zoom: 256%;
  top: -7px;
  color: #bfbfbf;
  left: 2px;
}
.emoticon-upload-container {
  float: left;
  width: 76px;
  height: 76px;
  padding: 6px;
  display: inline-block;
  border-radius: 4px;
  .ant-upload-picture-card-wrapper {
    height: 63px;
    width: 63px;
    border: 1px dashed #8c8c8c;
    border-radius: 4px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
  }
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;