@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.list-wrap {
  list-style: none;
  margin: 0;
  padding: 0;
}
.item {
  width: 248px;
  padding: 11px 15px;
}
.item:hover {
  background: rgba(56, 158, 13, 0.2);
}

.title {
  display: flex;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action {
  display: flex;
  align-items: center;
}

.pop-style {
  z-index: 20;
}

.pop-style :global(.ant-popover-inner-content) {
  padding: 0;
}

.high-light {
  color: #fa585a;
}

.modal-content {
  text-align: center;
}
.modal-account-text {
  padding-left: 8px;
  color: red;
}
.confirm-modal :global(.ant-btn-danger) {
  color: #389e0d;
  background: #389e0d;
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;