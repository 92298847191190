@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.group-line {
  width: 100%;
  // border-bottom: 1px solid #ccc;
  padding: 0px 12px;
  height: calc(100vh - 160px);
  overflow: auto;
  .group-apply-table {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
  }
}
th.table-th {
  color: #8c8c8c !important;
  font-size: 12px !important;
  height: 40px !important;
  background: #f5f5f5 !important;
  padding: 16px 12px 16px 4px !important;
}
td.table-th {
  padding: 16px 12px 16px 4px !important;
}
.group-apply-td1 {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  width: 89px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.group-apply-td2 {
  width: 32px;
}
.group-apply-td3 {
  color: #bfbfbf;
  font-size: 12px;
  width: 79px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.group-apply-td4 {
  color: #8c8c8c;
  font-size: 12px;
  width: 132px;
}

.group-apply-td5 {
  color: #bfbfbf;
  font-size: 12px;
  width: 79px;
}
.table-spe {
  position: relative;
  top: -20px;
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;