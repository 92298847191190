@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.functionWindow {
  overflow-y: hidden;
  height: 100vh;
  background: #fff;
  // .scroller();
}
.functionWindow-tabs {
  color: #bcbcbc;
  .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-right: 0;
  }
  .ant-tabs .ant-tabs-right-bar {
    width: 70px;
    text-align: center;
  }

  .ant-tabs-right-content {
    background: #e9e9e9;
    min-height: 100vh;
    padding: 0;
    .descriptions .item {
      margin-bottom: 5px;
      color: rgba(89, 89, 89, 0.6);
      font-size: 14px;
      span {
        color: #595959;
      }
    }
  }
  .ant-tabs-tab {
    margin: 0;
  }
}
.functionWindow-tabs .ant-tabs-tab {
  margin: 0 !important;
}
.side-right-bar-tabs .ant-tabs-nav {
  color: #8c8c8c;
}
.user-info .ant-tabs-nav {
  color: #262626;
  font-weight: bold;
}
.side-right-bar-tabs .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  width: 0;
}
.tabs-item {
  display: block;
  position: relative;
  padding: 18px 0;
  height: 70px;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  p {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-right: 0;
  }
}
.tabs-item-icon {
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.functionWindow-tabs-header {
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding: 32px 18px 16px 16px;
  color: rgba(0, 0, 0, 0.65);
  background: #e9e9e9;
  .header-btn {
    width: '60px';
    height: '24px';
    font-size: '12px';
    padding: 0;
  }
}
.functionWindow-tabs-card {
  margin: 10px auto;
  padding: 10px 0;
  width: 92%;
  // box-shadow: 0 1px 0 0 rgba(#787878, 0.1);
  h5 {
    font-size: 12px;
    text-align: left;
    color: rgba(#787878, 1);
  }
  p {
    margin-bottom: 5px;
    text-align: left;
    color: #333;
  }
  .functionWindow-tabs-card-title {
    margin: 0 auto 10px;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    text-align: left;
    color: #787878;
  }
  .functionWindow-tabs-card-actions {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin: 0 auto;
    padding: 10px 0 0;
    width: 100%;
    button {
      margin-right: 5px;
      border-radius: 4px;
    }
  }
}
.addedotherwx {
  .addedotherwx-total {
    display: inline;
    cursor: pointer;
    color: blue;
  }
}
.addedotherwx-modal-title {
  font-size: 16px;
  font-weight: bold;
}
.addedotherwx-modal-list {
  padding-left: 0;
  padding-inline-start: 0;
  li {
    padding: 12px 0;
    list-style: none;
    &:first-of-type {
      margin-top: 20px;
    }
    p {
      margin-bottom: 8px;
      font-size: 13px;
      color: #262626;
      display: flex;
      span {
        display: inline-block;
        width: 80px;
        color: #8c8c8c;
        &.text {
          flex: 1;
          color: #262626;
        }
      }
    }
  }
}
.qun-member-list {
  .ant-avatar-image {
    border-radius: 4px;
  }
  .isQunMaster {
    display: inline-block;
    margin-left: 5px;
    padding: 0 8px;
    box-shadow: inset 0 0 0 1px #e8e8e8;
    background: rgba(226, 226, 226, 1);
    border-radius: 2px;
    color: #8c8c8c;
    font-size: 12px;
  }
}

.qun-search {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
}

.group-item {
  .ant-list-item {
    padding-bottom: 4px;
  }
}

.qun-plus-btn {
  color: #54ab29;
  background-color: unset;
  border: 1px solid #54ab29 !important;
}

.im-list-item {
  padding-bottom: 12px;
}
.im-list-item-meta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.qun-divider {
  position: absolute !important;
  height: 100% !important;
  left: calc(50% - 8px);
  background: rgba(56, 158, 13, 0.1);
}

.group-style .ant-list-split .ant-list-item {
  border: unset;
}

.qrCode-headImg {
  border-radius: 4px;
  width: 56px;
  height: 56px;
}
.qrCode-qunName {
  margin-left: 12px;
  font-weight: 500;
  font-size: 16px;
  color: #262626;
}
.qrCode-size {
  display: block;
  margin: 0 auto;
  width: 280px;
  height: 280px;
}
.qrCode-tips {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  color: #ccc;
}
.mask-size {
  position: absolute;
  width: 90%;
  height: 260px;
  .qrCode-text {
    text-align: center;
    padding: 80px;
    font-size: 20px;
    font-weight: 500;
  }
}

.panel-content .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: unset;
}
.panel-content-order {
  padding: 20px;
}
.panel-content-order .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 16px;
  padding-left: 40px;
}
.panel-userdata {
  position: relative;
  .panel-userdata-action {
    position: absolute;
    top: 23px;
    right: 5px;
    transform: translateY(-50%);
  }
  .panel-userdata-input {
    margin-top: 10px;
    &:first-of-type {
      margin-top: 0;
    }
    input,
    span {
      display: inline-block;
    }
  }
}
.custom-upload-list {
  float: left;
  width: 400px;
  line-height: 48px;
  margin-right: 8px;
}

.upload-list-inline .ant-upload-list-picture-card {
  position: absolute;
  top: 116px;
  width: 288px;
  zoom: 1.5;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  width: 100%;
}
.ant-upload.ant-upload-select-picture-card {
  height: 40px;
  border: 0;
  border-radius: 0px;
}

.chat-sub-title {
  margin-top: -20px;
}
.chat-pannel1 {
  height: 245px;
  border: 1px solid #d9d9d9;
  margin-top: 12px;
}
.chat-pannel-header1 {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .chat-pannel-header-icon {
    margin-right: 5px;
    font-size: 18px;
  }
  .anticon-file-image {
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  .chat-pannel-header-callme {
    align-self: flex-end;
    cursor: pointer;
    font-size: 16px;
  }
}
.chat-pannel-content1 {
  padding: 0 6px;
  height: calc(100% - 46px);
}
.user-data .item {
  margin-bottom: 16px;
  color: rgba(89, 89, 89, 0.6);
  font-size: 14px;
  span {
    color: #595959;
  }
}
.quick-reply-info {
  min-height: 100vh;
  padding: 0px 0px 0 8px;
  background: #e9e9e9;
  .scroll-box {
    height: calc(100vh - 88px);
    .scroller();
    .quick-reply-select {
      margin: 10px 0;
      width: calc(100% - 16px);
    }
    .message-block {
      position: relative;
      width: 100%;
      font-weight: 500;
      font-size: 12px;
      img {
        max-width: 100%;
      }
      .text-block {
        text-overflow: ellipsis;
        width: 260px;
        overflow: hidden;
      }
      .close-media {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
      }
    }
  }
}

.meterial-library {
  min-height: 100vh;
  padding: 0px 0px 0 8px;
  background: #e9e9e9;
  .scroll-box {
    height: calc(100vh - 88px);
    .scroller();
  }
  .message-block {
    position: relative;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    img {
      max-width: 100%;
    }
    .text-block {
      text-overflow: ellipsis;
      // width: 260px;
      overflow: hidden;
      margin-right: 22px;
    }
    .close-media {
      color: #bfbfbf;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 0;
    }
  }
}

.link-group {
  position: relative;
  display: inline-block;
  margin-left: 60px;
  a {
    padding: 0 10px;
    color: #676767;
  }
}
.quick-reply-layout {
  background: #fff;
  height: 584px;
  margin-top: -24px;
  // padding: 0px 16px 0 8px;
  .quick-reply-item {
    border-bottom: 1px solid rgba(38, 38, 38, 0.2);
    margin: 0 !important;
    height: 48px;
    line-height: 48px;
  }
  .quick-reply-item-menu {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 500;
    position: relative;
  }
  .quick-reply-item-content {
    width: 100%;
    position: relative;
    padding-right: 17%;
  }
  .quick-reply-item-menu-op {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    width: 60px;
    justify-content: space-between;
  }
  .quick-reply-item-li {
    cursor: pointer;
    border-bottom: 1px solid rgba(38, 38, 38, 0.17) !important;
    color: #000;
    font-size: 12px;
    padding: 15px;
    line-height: 17px;
  }
  .quick-reply-content {
    margin-left: 126px;
    background: #e9e9e9;
    // border: 1px solid rgba(38, 38, 38, 0.17);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  .list-style {
    overflow: auto;
    flex: 1;
    border: 1px solid rgba(38, 38, 38, 0.17);
  }
  .button-content {
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
}
.ant-modal-footer {
  border: 0;
}

.recommend-commodity-layout {
  min-height: 100vh;
  padding: 0px 0px 0 8px;
  background: #e9e9e9;
  .scroll-box {
    height: calc(100vh - 128px);
    .scroller();
  }
}
.recommend-commodity-card {
  position: relative;
  width: 284px;
  height: 90px;
  // background: #fafafa;
  background: #fff;
  border-radius: 4px;
  margin: 14px auto;
  .recommend-commodity-info {
    width: calc(100% - 76px);
    padding: 8px;
    .title {
      font-size: 12px;
      color: #262626;
      line-height: 17px;
      padding: 0;
    }
    .price {
      color: #fa585a;
      font-weight: 500;
      line-height: 24px;
    }
    .sale {
      font-size: 12px;
      color: #8c8c8c;
      .sale-num {
        float: right;
      }
    }
  }
  .top-tip {
    position: absolute;
    right: 0;
    width: 44px;
    height: 21px;
    background: #eaf7e4;
    box-shadow: 0px 2px 2px 0px rgba(38, 38, 38, 0.15);
    border-radius: 0px 4px;
    color: #389e0d;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
  }
}

.scroll-box {
  height: calc(100vh - 88px);
  .scroller();
}

.managerHead {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}

.managerHead-dialog {
  width: 38px;
  height: 38px;
  margin: 0 13px 0 7px;
  border-radius: 4px;
}

.qun-manager-dialog {
  max-height: 520px;
  overflow: auto;
  margin-top: -24px;
  .scroll-box {
    height: 520px;
    .scroller();
  }
}
.qun-member-loadmore {
  text-align: center;
  margin-top: 12px;
  height: 32px;
  line-height: 32px;
}
.chat-pannel2 {
  height: 130px;
  border-radius: 4px;
  background: #fff;
}
.chat-pannel-header2 {
  padding: 2px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .chat-pannel-header-icon {
    margin-right: 5px;
    font-size: 18px;
  }
  .anticon-file-image {
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  .chat-pannel-header-callme {
    align-self: flex-end;
    cursor: pointer;
    font-size: 16px;
  }
}
.chat-pannel-content2 {
  padding: 0 6px;
  height: calc(100% - 66px);
  line-height: 20px;
}
.chat-pannel-bootom2 {
  float: right;
}

.share-card {
  background: white;
  margin-right: 20px;
  padding: 12px;
  border-radius: 4px;
  text-align: left;
  .share-title {
    color: #262626;
    font-size: 16px;
    max-height: 46px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .share-content {
    display: flex;
    justify-content: space-between;
    color: #8c8c8c;
    font-size: 12px;
    margin-top: 8px;
    .share-text {
      max-height: 48px;
      overflow: hidden;
      line-height: 16px;
      padding-right: 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .share-img {
      width: 48px;
      height: 48px;
      border-radius: 4px;
    }
  }
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;