@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.wechat-im {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  background: #e9e9e9;

  .sider {
    overflow: hidden;
    color: #000;
    background: #fcfcfc;
  }
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;