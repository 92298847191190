@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.search-container {
  // margin-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  height: 54px;
  .sider-search {
    display: block;
    margin-right: 8px;
    width: 208px;
    height: 32px;
    .ant-input {
      height: 100%;
      font-size: 12px;
      background: rgba(#f5f5f5, 1);
      color: #000;
      border: none;
      outline: none;
    }
    svg {
      fill: rgba(#f5f5f5, 1);
    }
  }
  .hidden {
    display: none;
  }
  .visible {
    display: block;
    background: #fff;
  }
  .scroll-container {
    box-sizing: border-box;
    overflow: hidden !important;
    position: absolute;
    z-index: 99;
    top: 124px;
    width: 100%;
    height: calc(100vh - 54px);
    // min-height: 300px;
    left: 50%;
    background: #fcfcfc;
    text-align: center;
    transform: translateX(-50%);
    .scroller();
    .scroll-container-list {
      overflow: auto;
      max-height: calc((100vh - 200px) / 2);
    }
    .user-item-name {
      text-align: left;
    }
    .scroll-container-title,
    .scroll-container-loadmore,
    .scroll-container-empty-prompt {
      box-sizing: border-box;
      padding: 0 20px 0 10px;
      font-size: 12px;
      line-height: 1.5;
      text-align: left;
      color: rgba(120, 120, 120, 1);
    }
    .scroll-container-title {
      margin-top: 16px;
    }
    .scroll-container-empty-prompt {
      margin: 10px auto;
      font-size: 14px;
      color: rgba(120, 120, 120, 0.6);
      .friend-img,
      .group-img {
        margin-right: 12px;
        width: 32px;
        height: 32px;
      }
    }
    .scroll-container-loadmore {
      margin-top: 10px;
      font-size: 12px;
      cursor: pointer;
      color: #389e0d;
      &:hover {
        color: #2d800a;
      }
    }
    .ant-list-empty-text {
      padding: 0;
      height: 0;
    }
    .ant-empty {
      display: none;
      height: 0;
    }
  }
}

.visible-container {
  background: #fff;
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;