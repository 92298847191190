@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.message-box {
  position: relative;
  height: calc(100vh - 280px);
  font-size: 14px;
  color: #000;
  .unreadmessageprompt {
    cursor: pointer;
    z-index: 99;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 16px;
    padding: 0 8px 0 16px;
    width: 118px;
    height: 32px;
    font-size: 12px;
    color: #389e0d;
    background: #dcece2;
    border-radius: 16px 0 0 16px;
    .unreadmessageprompt-downicon {
      transform: rotateZ(-90deg);
    }
    p {
      margin: 0 10px 0 3px;
      white-space: nowrap;
    }
    .unreadmessageprompt-close {
      color: #8c8c8c;
    }
  }
}
.message-scroll-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .scroller();
  .message-list {
    padding: 10px 20px 0;
    .message-item {
      display: flex;
      margin-bottom: 16px;
      width: 100%;
      .message-avatar {
        width: 40px;
        height: 40px;
        border-radius: 4px;
      }
      .message-wrapper {
        display: flex;
        // width: 100%;
        &.center {
          margin: 0 auto;
        }
      }
      .message-text-wrapper {
        display: inline-block;
        position: relative;
        padding: 8px 20px;
        border-radius: 0 18px 18px 18px;
        max-width: 500px;
        .msgloading {
          opacity: 0;
          position: absolute;
          top: 50%;
          left: -20px;
          transform: translateY(-50%);
          animation: loadingFrames 0.1s 1 linear forwards;
          animation-delay: 1s;
        }
        @keyframes loadingFrames {
          100% {
            opacity: 1;
          }
        }
        .message-text {
          overflow: hidden;
          font-family: Helvetica Neue, Helvetica, Hiragino Sans GB, Microsoft YaHei,
            \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
          margin: 0;
          white-space: pre-wrap;
          word-break: break-word;
          text-align: left;
        }
        .message-share {
          .app {
            background-color: #fff;
            margin: 2px;
            display: block;
            overflow: hidden;
            text-decoration: none;
            max-width: 200px;
            min-width: 250px;
            cursor: pointer;
            .title {
              font-weight: 400;
              color: #262626;
              font-size: 16px;
            }
            .cover {
              width: 48px;
              height: 48px;
              float: left;
              border-radius: 4px;
              -moz-border-radius: 4px;
              -webkit-border-radius: 4px;
              margin-left: 10px;
            }
            .desc {
              overflow: hidden;
              color: #888;
              max-height: 5em;
              word-break: break-all;
            }
          }
        }
        .link-show-more {
          cursor: pointer;
          padding: 10px 0;
          color: #54ab29;
        }
      }
      .message-audio {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 0 18px 18px 18px;
        height: 39px;
        .message-audio-time {
          margin: 0;
          position: absolute;
          left: 40px;
          font-size: 12px;
          color: #262626;
        }
        &::before {
          position: absolute;
          top: 14px;
          border: 6px solid transparent;
          content: '';
        }
        &.active {
          .message-audio-icon {
            &::before {
              animation: YYAnimation-before 1s linear infinite;
            }
            &::after {
              animation: YYAnimation-after 1s linear infinite;
            }
          }
        }

        .message-audio-icon {
          @radius: 3px;
          position: absolute;
          top: 50%;
          border-top: @radius solid transparent;
          border-bottom: @radius solid transparent;
          border-right: @radius solid rgba(#333, 0.7);
          border-radius: 50%;
          transition: all 0.3s;
          transform: translateY(-50%);
          &::before,
          &::after {
            opacity: 1;
            z-index: -1;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: @radius * 5;
            height: @radius * 5;
            border: @radius * 0.7 solid transparent;
            border-right: @radius * 0.7 solid rgba(#333, 0.7);
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
          &::after {
            width: @radius * 8;
            height: @radius * 8;
          }
        }
      }
      .message-video-icon {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        color: #fff;
        z-index: 99;
      }
      @keyframes YYAnimation-before {
        0% {
          opacity: 0;
        }
        29% {
          opacity: 0;
        }
        30%,
        100% {
          opacity: 1;
        }
      }
      @keyframes YYAnimation-after {
        0% {
          opacity: 0;
        }
        69% {
          opacity: 0;
        }
        70%,
        100% {
          opacity: 1;
        }
      }
      .message-container {
        padding: 0 10px;
      }
      .video-mask {
        display: inline-block;
        min-height: 80px;
        max-height: 360px;
        max-width: 360px;
        min-width: 80px;
        border-radius: 0 18px 18px 18px;
        height: 320px;
        width: 180px;
        position: absolute;
        background: rgba(0, 0, 0, 0.45);
        z-index: 20;
      }
      .message-img {
        display: inline-block;
        max-height: 100%;
        // height: 160px;
      }
      .img-other {
        border-radius: 0 18px 18px 18px;
      }
      .img-self {
        border-radius: 18px 0 18px 18px;
      }
      .message-sys {
        font-size: 12px;
        color: #aaa;
        text-align: center;
        word-break: break-word;
        width: 100%;
      }
      .date {
        font-size: 12px;
        padding: 3px 10px;
        color: #bfbfbf;
      }
      .self-date {
        text-align: right;
      }
      .other {
        text-align: left;
      }
      .sys {
        text-align: center;
      }
      .self {
        text-align: right;
        clear: both;
      }
      &.self {
        flex-direction: row-reverse;
        text-align: right;
        .message-text-wrapper {
          float: right;
          border-radius: 18px 0 18px 18px;
        }
        .revoke {
          .message-text-wrapper {
            color: #8c8c8c;
            background: rgba(#389e0d, 0.1);
          }
        }
        .message-text-wrapper,
        .message-audio {
          text-align: right;
          background: #b2e281;
        }
        .message-audio {
          border-radius: 18px 0 18px 18px;
        }
        .message-audio-icon {
          right: 17px;
          transform: translateY(-50%) rotateZ(180deg);
          &::before,
          &::after {
            transform: translate(-50%, -50%);
          }
        }
        .message-container {
          display: flex;
          justify-content: flex-end;
        }
      }
      &.other {
        text-align: left;
        .message-text-wrapper,
        .message-audio {
          background: #fff;
        }
        .revoke {
          .message-text-wrapper {
            color: #bfbfbf;
            background: #fff;
          }
        }
        .message-avatar {
          transform: translateY(6px);
        }
        .message-audio-icon {
          left: 17px;
        }
        .avatar-right {
          margin-left: 6px;
        }
        .avatar-left {
          margin-right: 6px;
        }
      }
      &.sys {
        justify-content: center;
        text-align: center;
        .message-text-wrapper {
          &::before {
            border-right: 6px solid #fff;
            right: 50%;
          }
        }
      }
    }
  }
}
.msg-ins {
  width: 48px;
  height: 48px;
  background: url('http://img1.lukou.com/static/p/commodity/img/20190725-135034.png');
}
.chat-invitation {
  color: #262626;
  margin: 40px 0;
  .chat-invitation-avatar {
    width: 100px;
    height: 100px;
    border-radius: 4px;
  }
  .chat-invitation-member {
    font-size: 16px;
    line-height: 42px;
  }
  .chat-invitation-message {
    font-weight: 500;
    font-size: 20px;
    line-height: 72px;
  }
  .chat-invitation-info {
    text-align: left;
    font-size: 14px;
    line-height: 32px;
    margin-top: 50px;
    p {
      margin: 0;
    }
  }
}
.mask-btn {
  position: absolute;
  width: 106px;
  height: 34px;
  background: rgba(255, 255, 255, 0.7);
  left: -1px;
  top: -1px;
}
.imgPreviewModal {
  position: absolute;
  background: #fff;
  padding: 24px 24px 68px 24px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 900;
  border-radius: 4px;
  min-width: 260px;
  text-align: center;
  box-shadow: 0px 6px 30px 0px rgba(38, 38, 38, 0.2);
  .imgPreviewBtn {
    display: flex;
    width: 213px;
    justify-content: space-between;
    bottom: 20px;
    position: absolute;
    left: 50%;
    margin-left: -107px;
  }
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;