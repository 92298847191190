@main-header-height: 54px;

@client-item-height: 70px;

@green-color: #389e0d;

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.scroller {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
}
.ant-menu-item-selected {
  background-color: rgba(38, 38, 38, 0.1) !important;
}

@font-face {
  font-family: 'iconfont'; /* project id 1428597 */
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot');
  src: url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.woff') format('woff'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1428597_jtwxcrbsb3f.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-arrow .ant-select-arrow-icon {
  color: #888;
}

.record-message {
  .message {
    // padding: 17px 16px 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .message-inner {
    padding: 10px 2px;
    display: flex;
    .message-avatar {
      padding: 0 10px;
    }
    .message-author-name {
      padding-right: 10px;
      font-size: 14px;
      color: #8c8c8c;
    }
    .message-content-detail {
      position: relative;
      padding: 5px 0 20px;
      font-size: 14px;
      color: #262626;
      word-break: break-all;
      max-width: 300px;
      overflow: hidden;
    }
    .message-coentetn-detail-promopt {
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: 1px;
      color: #bfbfbf;
      word-break: break-all;
    }
    .message-content-detail-img {
      width: 100%;
      height: auto;
      border-radius: 0 18px 18px 18px;
      min-height: 80px;
      max-height: 160px;
      max-width: 160px;
      min-width: 80px;
    }
    .message-footer {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .ant-avatar {
    width: 38px;
    height: 38px;
    border-radius: 30%;
    border-radius: 4px;
  }

  .messageRight {
    padding: 5px 5px 0px;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    .message-inner {
      flex-direction: row-reverse;
      .message-content-author {
        display: flex;
        flex-direction: row-reverse;
        font-size: 14px;
        color: #8c8c8c;
      }
      .message-author-name {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 500;
        padding-left: 10px;
      }
      .message-content-detail {
        // display: flex;
        // flex-direction: row-reverse;
        // text-align: right;
      }
      .message-content-detail-img {
        // width: 100%;
        // height: auto;
        min-height: 80px;
        max-height: 160px;
        max-width: 160px;
        min-width: 80px;
        border-radius: 18px 0 18px 18px;
      }
      .message-coentetn-detail-promopt {
        right: 0;
      }
    }
  }

  .emoji-text {
    margin: 4px;
    text-align: left;
  }
}
.message-content-author-time {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 22px;
}
.record-sys {
  font-size: 13px;
  text-align: center;
  .sysMsg {
    color: #8c8c8c;
  }
}
.message-img-detail-img {
  width: 100%;
  height: auto;
}

@primary-color: #389e0d;@link-color: #389e0d;@disabled-color: #E2E2E2;@border-radius-base: 2px;